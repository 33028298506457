import { Component, OnInit, ViewEncapsulation, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../services/api.service';
import { ConfigurationService } from '../services/configuration.service';
import { Resource } from '../models/Resource';
import { ResourceType } from '../models/ResourceType';
import { Tag } from '../models/Tag';
import { GeoTag } from './../models/GeoTag';
@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceComponent implements OnInit {
	public resource: Resource = new Resource();
  public expanded_types: string[] = [];


  constructor(
    private configurationService: ConfigurationService,
    private route: ActivatedRoute,
    private apiService: APIService,
    private changeDetector: ChangeDetectorRef
	) { }

  ngOnInit() {
    this.apiService.getResource(this.route.snapshot.params.resource_id).subscribe((response: Resource) => {
      this.resource = response;
      this.configurationService.setTitle(response.title + ' resource');
      this.changeDetector.detectChanges();
      console.log(this.resource)
    });
  }

  public toggleExpanded(type: string) {
      if (this.expanded_types.includes(type)) {
          this.expanded_types.splice(this.expanded_types.indexOf(type), 1);
      } else {
          this.expanded_types.push(type);
      }
      return false;
  }

  public getDisplayedArr(type: string, arr: Resource[] | GeoTag[] | Tag[]): Resource[] | GeoTag[] | Tag[] {
    return (this.expanded_types.includes(type)) ? arr : arr.slice(0, 4);
	}

  public viewerJSUrlCast(url: string): string {
      return url.replace(this.configurationService.api_url, this.configurationService.api_url + '/ViewerJS/index.html#');
  }

  public viewerMicrosfotCast(url: string): string {
      return 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url);
  }

}
