import { Component, OnInit, HostListener } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public siteName: string;
    isSticky: true | false = false;

    constructor(private configurationService: ConfigurationService) { }

    ngOnInit() {
        this.siteName = this.configurationService.siteName;
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        this.isSticky = window.pageYOffset >= 100;
    }

}
