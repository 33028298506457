import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Collection } from '../models/Collection';
import { Resource } from '../models/Resource';
import { ResourceType } from '../models/ResourceType';
import { Tag } from '../models/Tag';
import { GeoTag } from './../models/GeoTag';

@Component({
    selector: 'app-item-carousell',
    templateUrl: './item-carousell.component.html',
    styleUrls: ['./item-carousell.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ItemCarousellComponent implements OnInit {

    @Input() items: Array<Collection | Resource | Tag | GeoTag>;
    @Input() type: string;

    constructor() { }
    public expanded_types: string[] = [];

    ngOnInit() {
    }

    public toggleExpanded(type: string) {
        if (this.expanded_types.includes(type)) {
            this.expanded_types.splice(this.expanded_types.indexOf(type), 1);
        } else {
            this.expanded_types.push(type);
        }
        return false;
    }

    public getDisplayedArr(type: string, arr: Resource[] | GeoTag[] | Tag[]): Resource[] | GeoTag[] | Tag[] {
        return (this.expanded_types.includes(type)) ? arr : arr.slice(0, 4);
    }

}
