import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';

import OlMap from 'ol/Map';
import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import OlImageLayer from 'ol/layer/Image';
import OlVectorSource from 'ol/source/Vector';
import OlLayerGroup from 'ol/layer/Group';
import OlView from 'ol/View';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Zoom from 'ol/control/Zoom';
import ImageWMS from 'ol/source/ImageWMS';
import { ConfigurationService } from '../services/configuration.service';
import { Resource } from '../models/Resource';
import { APIService } from '../services/api.service';
import { GeoTag } from '../models/GeoTag';
import { Tag } from '../models/Tag';
import { ActivatedRoute } from '@angular/router';
import { fromLonLat, transform } from 'ol/proj';
import { ResourceType } from '../models/ResourceType';
import { NgxSmartModalService } from 'ngx-smart-modal';
import LayerSwitcher from 'ol-layerswitcher';
import ImageArcGISRest from 'ol/source/ImageArcGISRest';
import Stamen from 'ol/source/Stamen';
import OSM from 'ol/source/OSM';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import TileWMS from 'ol/source/TileWMS';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})


export class MapComponent implements OnInit, AfterViewInit {

    @ViewChild('spatialMap') mapElement: ElementRef;
    public map: OlMap;
    private source: OlXYZ = new OlXYZ({
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'http://localhost:4200'
    });
    private mapLayer: OlTileLayer;
    private markerLayer: OlVectorLayer;
    private view: OlView;
    private markerSource = new OlVectorSource();
    public expanded_types: string[] = [];
    public resources: Array<Resource> = [];
    public selectedGeoTag: GeoTag;
    public wmsResult;
    private headerHeight: number;
    public cont_height = 800;

    constructor(
        private configurationService: ConfigurationService,
        private apiService: APIService,
        private route: ActivatedRoute,
        private ngxSmartModalService: NgxSmartModalService,
        private http: HttpClient
    ) { }

    // @HostListener('window:resize', ['$event'])
    resize() {
        // this is pretty hacky
        const internal_element_height = document.querySelector('html').clientHeight;
        const footer_element_height = document.querySelector('footer').clientHeight;
        this.cont_height = (internal_element_height - ((this.headerHeight) + footer_element_height));
    }

    ngOnInit() {

        this.headerHeight = document.querySelector('nav').clientHeight;
        this.resize();

        this.configurationService.setTitle('Maps');

        this.view = new OlView({
            center: fromLonLat([176.1625, -38.0809]), // has to be reversed for some obseen reason
            zoom: 12,
        });

        const markerStyle = new Style({
            image: new Icon(({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                opacity: 0.75,
                src: '/assets/images/map-pin.png'
            }))
        });

        this.markerLayer = new OlVectorLayer({
            source: this.markerSource,
            style: function (el, resolution) {
                console.log(el, el.getProperties().geoTag);
                return new Style({
                    image: new Icon(({
                        opacity: 1,
                        src: el.getProperties().geoTag.thumbnail_url,
                        scale: 0.1
                    }))
                });
            }
        });

    }

    ngAfterViewInit() {
        let mapLayers = [];
        this.apiService.getResourcesBasic().subscribe((resources: Array<Resource>) => {
            this.resources = resources;
            resources.filter((resource: Resource) => {
                // TODO get this string dynamically or from constant
                return resource.type.title.indexOf('Map resource') > -1;
            }).forEach((resource) => {
                const newLayer = new OlTileLayer({
                    title: resource.title,
                    visible: false,
                    source: new TileWMS({
                        url: 'https://geoserver.epiphron.co.nz:443/wms',
                        params: {
                            'FORMAT': 'image/png',
                            'VERSION': '1.1.1',
                            tiled: true,
                            'LAYERS': resource.title.replace(/ /g, '_').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '_'),
                            'exceptions': 'application/vnd.ogc.se_inimage',
                        },
                        crossOrigin: 'http://localhost:4200',
                        serverType: 'geoserver'
                    })
                });
                mapLayers.push(newLayer);
            });

            this.route.params.subscribe(url_params => {
                if (typeof url_params.map_layers !== 'undefined') {

                    mapLayers = [];
                    resources.filter((resource: Resource) => {
                        return resource.type.title.indexOf('Map layer') > -1;
                    }).forEach((resource_arr_id) => {
                        const resource: Resource = this.apiService.getResourceById(Number(resource_arr_id), this.resources);
                        const newLayer = new OlTileLayer({
                            title: resource.title,
                            visible: false,
                            source: new TileWMS({
                                url: 'https://geoserver.epiphron.co.nz:443/wms',
                                params: {
                                    'FORMAT': 'image/png',
                                    'VERSION': '1.1.1',
                                    tiled: true,
                                    'LAYERS': resource.title.replace(/ /g, '_').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '_'),
                                    'exceptions': 'application/vnd.ogc.se_inimage',
                                },
                                crossOrigin: 'http://localhost:4200',
                                serverType: 'geoserver'
                            })
                        });
                        mapLayers.push(newLayer);
                    });
                }
            });

            this.map = new OlMap({
                target: this.mapElement.nativeElement,
                layers: [
                    new OlLayerGroup({
                        // A layer must have a title to appear in the layerswitcher
                        'title': 'Base maps',
                        layers: [
                            this.markerLayer,
                            new OlTileLayer({
                                title: 'LINZ',
                                type: 'base',
                                visible: true,
                                source: this.source
                            }),
                            new OlTileLayer({
                                // A layer must have a title to appear in the layerswitcher
                                title: 'Water color',
                                // Again set this layer as a base layer
                                type: 'base',
                                visible: false,
                                source: new Stamen({
                                    layer: 'watercolor',
                                    crossOrigin: 'http://localhost:4200'
                                })
                            }),
                            new OlTileLayer({
                                // A layer must have a title to appear in the layerswitcher
                                title: 'OSM',
                                // Again set this layer as a base layer
                                type: 'base',
                                visible: false,
                                source: new OSM({
                                    crossOrigin: 'http://localhost:4200'
                                })
                            })
                        ]
                    }),
                    new OlLayerGroup({
                        // A layer must have a title to appear in the layerswitcher
                        title: 'Overlays',
                        // Adding a 'fold' property set to either 'open' or 'close' makes the group layer
                        // collapsible
                        fold: 'open',
                        layers: mapLayers
                    })
                ],
                view: this.view,
                renderer: 'canvas',
                controls: [
                    new Zoom(),
                    // new LayerSwitcher()
                ],
                interaction: []
            });
            const layerSwitcher = new LayerSwitcher({
                tipLabel: 'Légende', // Optional label for button
                groupSelectStyle: 'children' // Can be 'children' [default], 'group' or 'none'
            });
            this.map.addControl(layerSwitcher);
            this.map.on('singleclick', (evt) => {
                this.wmsResult = undefined;
                this.map.forEachLayerAtPixel(evt.pixel, (layer) => {
                    console.log(layer.getSource());
                    const view = this.map.getView();
                    const viewResolution = view.getResolution();
                    const source = layer.getSource();
                    const url = source.getGetFeatureInfoUrl(
                        evt.coordinate, viewResolution, view.getProjection(),
                        { 'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': 50 });
                    console.log(url);
                    if (url) {
                        this.http.get(url).pipe(map((response) => {
                            this.wmsResult = response;
                        }, (error) => {
                            console.log('GeoServer WMS Error:', error);
                        })).subscribe((response) => {
                            console.log('done');
                        });
                    } else {
                        this.wmsResult = undefined;
                    }
                });
            });
            this.map.on('pointermove', (evt) => {
                if (evt.dragging) {
                    return;
                }
                const pixel = this.map.getEventPixel(evt.originalEvent);
                const hit = this.map.forEachLayerAtPixel(pixel, (layer) => {
                    return (layer.type === 'IMAGE') ? true : false;
                });
                this.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
            });
            this.map.on('rendercomplete', () => {
                this.resize();
                this.map.updateSize();
            });
        });

    }

}
