import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class ConfigurationService {

  public api_url = 'https://api.mgpi.co.nz';
// public api_url = 'http://localhost:8888';

  public siteName = 'Māori Genuine Progress Indicator Project';
  public siteCopyright = 'Te Toi Ōhanga Limited™, All Rights Reserved.';

  constructor(private title: Title) { }

  setTitle(newTitle: string) {
    if (newTitle.length > 0) {
      this.title.setTitle(newTitle);
    } else {
      this.title.setTitle(this.siteName);
    }
  }

}
