import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsComponent implements OnInit, AfterViewInit {

    public loaded = false;
    constructor(private configurationService: ConfigurationService,
        private changeDetector: ChangeDetectorRef,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any) { }

    ngOnInit() {
      this.configurationService.setTitle('Terms of use');
    }

    ngAfterViewInit() {
        this.loaded = true;
        this.changeDetector.detectChanges();
        this.pageScrollService.scroll({
            document: this.document,
            scrollTarget: '.internal-content',
          });
    }
}
