import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor(private configurationService: ConfigurationService) { }

  ngOnInit() {
    this.configurationService.setTitle('Our Story');
  }

}
