
import { zip as observableZip } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { APIService } from '../services/api.service';
import { Resource } from '../models/Resource';
import { ResourceType } from '../models/ResourceType';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Tag } from '../models/Tag';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { LoadImageProps } from 'ng-lazyload-image';
import { GeoTag } from '../models/GeoTag';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsComponent implements OnInit {

    public tags: Tag[] = [];
    public resources: Resource[] = [];
    public searchTerms: string[] = [];

    public expanded_types: string[] = [];

    constructor(
        private configurationService: ConfigurationService,
        private apiService: APIService,
        public sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private ngxSmartModalService: NgxSmartModalService,
        private changeDetector: ChangeDetectorRef
    ) { }

    // Resource display logic

    public openModal(resource: Resource): void {
        this.ngxSmartModalService.setModalData(resource, 'resourceModal');
        this.ngxSmartModalService.getModal('resourceModal').open();
    }

    public clearModelData() {
        this.ngxSmartModalService.resetModalData('resourceModal');
    }

    public openGraphModal(resource: Resource): void {
        this.ngxSmartModalService.setModalData(resource, 'resourceChartModal');
        this.ngxSmartModalService.getModal('resourceChartModal').open();
    }

    public clearGraphModelData() {
        this.ngxSmartModalService.resetModalData('resourceChartModal');
    }

    public toggleExpanded(type: string) {
        if (this.expanded_types.includes(type)) {
            this.expanded_types.splice(this.expanded_types.indexOf(type), 1);
        } else {
            this.expanded_types.push(type);
        }
        return false;
    }

    public getDisplayedArr(type: string, arr: Resource[] | GeoTag[] | Tag[]): Resource[] | GeoTag[] | Tag[] {
        return (this.expanded_types.includes(type)) ? arr : arr.slice(0, 4);
    }

    public countTotalResults(): Number {
        let totalCount = 0;
        this.apiService.getResourceTypes(this.result_resources).forEach((type: ResourceType) => {
            totalCount += this.apiService.filterResourcesByType(type.title, this.result_resources).length;
        });

        return totalCount;
    }

    public filterResultsByType(type: string): Resource[] {
        return this.apiService.filterResourcesByType(type, this.result_resources);
    }

    public getResourceTypes(): ResourceType[] {
        return this.apiService.getResourceTypes(this.result_resources);
    }

    public viewerJSUrlCast(url: string): string {
        return url.replace(this.configurationService.api_url, this.configurationService.api_url + '/ViewerJS/index.html#');
    }

    public viewerMicrosfotCast(url: string): string {
        return 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url);
    }

    get result_resources(): Resource[] {
        return this.resources;
    }

    // End resource display logic

    public formattedSearchTerms(): string {
        return this.searchTerms.join(', ');
    }

    ngOnInit() {

        this.apiService.getTagsBasic().subscribe((tags: Tag[]) => {
            this.tags = tags;
            this.route.params.subscribe(params => {
                if (typeof params.tag_ids !== 'undefined') {
                    const resource_requests = [];
                    this.searchTerms = params.tag_ids.split(',').map((tag_id: string) => {
                        resource_requests.push(this.apiService.getTagResources(Number(tag_id)));
                        return this.apiService.getTagById(Number(tag_id), this.tags).title;
                    });
                    observableZip(...resource_requests).subscribe((resources: [Resource[]]) => {
                        resources.filter((sub_arr: Resource[]) => {
                            return sub_arr.length > 0;
                        }).map((resource: Resource[]) => {
                            resource.map((arr_resource) => {
                                this.resources.push(arr_resource);
                            });
                        });
                        this.changeDetector.detectChanges();
                    });
                    this.configurationService.setTitle('Discover more about ' + this.searchTerms.join(', '));
                }
            });
        });

    }

}
