import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent implements OnInit, AfterViewInit {

    public loaded = false;

    constructor(private configurationService: ConfigurationService,
        private changeDetector: ChangeDetectorRef,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any) { }

    ngOnInit() {
        this.configurationService.setTitle('Contact us');
    }

    ngAfterViewInit() {
        this.loaded = true;
        this.changeDetector.detectChanges();
        this.pageScrollService.scroll({
            document: this.document,
            scrollTarget: '.internal-content',
          });
    }

}
